import { FloatingLabel, Form } from 'react-bootstrap';
import { cardValidator } from '@property-folders/common/util/cc-validation';
import { CardType, getCardType } from '../CardLogo';
import { useState } from 'react';

interface Props {
  setValid: (valid: boolean) => void;
  setCardType: (cardType: CardType) => void;
  setValue: (cardNumber: string) => void;
}

export function CardNumber({
  setValid,
  setValue,
  setCardType
}: Props) {
  const [cardNumber, setCardNumber] = useState('');
  const [flagCcInvalid, setFlagCcInvalid] = useState(false);
  const [ccValid, setCcValid] = useState(true);

  return <FloatingLabel label='Card Number' className='flex-grow-1 common-label'>
    <Form.Control
      placeholder=''
      type='text'
      autoComplete='cc-number'
      isInvalid={!ccValid && flagCcInvalid}
      onChange={(e) => {
        setFlagCcInvalid(false);
        let cursorPosition = e.target.selectionStart;
        let settingCursorPosition = false;
        let modNewVal = e.target.value;
        const matchingDeletedSpace = modNewVal.match(/[0-9]{5,8}/)?.[0];
        if (matchingDeletedSpace) {
          const startPos = modNewVal.indexOf(matchingDeletedSpace);
          if (cardNumber[startPos + 4] === ' ') {
            settingCursorPosition = true;
            modNewVal = modNewVal.slice(0, startPos + 3) + modNewVal.slice(startPos + 4);

            if (cursorPosition !== null) {
              cursorPosition--;
            }
          }
        }
        const numbersOnly = modNewVal.replace(/\D/g, '');
        let output = '';
        for (let i = 0; i < 4; i++) {
          const start = i * 4;
          const testSegment = numbersOnly.slice(start, start + 4);
          if (testSegment) {
            if (i > 0) {
              output += ' ';
            }

            output += testSegment;
          }
        }

        e.target.value = output;
        const { isPotentiallyValid, isValid } = cardValidator.number(numbersOnly);
        setCcValid(isValid);
        if (!isPotentiallyValid) {
          setFlagCcInvalid(true);
        }

        setCardType(getCardType(output));
        setCardNumber(output);

        if (settingCursorPosition) {
          e.target.setSelectionRange(cursorPosition, cursorPosition);
        }
      }}
      onBlur={(e) => {
        if (!ccValid) {
          setFlagCcInvalid(true);
        }

        setValid(ccValid);
        setValue(cardNumber);
      }}
    />
    <div className='invalid-feedback'>Please enter a valid Credit Card Number</div>
  </FloatingLabel>;
}
